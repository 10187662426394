.productList {
	margin: 55px 10px 0 10px;
	scroll-behavior: smooth;
	h1 {
		margin: 55px 0 0 0;
		text-align: right;
	}
	.list{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 10px;
		grid-row-gap: 10px;
	}
}