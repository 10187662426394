.header{
	position: fixed;
	min-width: 100%;
	top: 0;
	left: 0;
	z-index: 1;
}
.sliderCategory{
	padding: 10px;
	outline: 0;
	background-color: var(--tg-theme-bg-color);
	border-bottom: 1px solid var(--tg-theme-hint-color);
}
.sliderCategory a{
	display: block;
	border-radius: 25px;
	text-decoration: none;
	line-height: 34px;
	margin: 0 3px;
	padding: 0 16px;
	transition: color, background-color ease-in-out 200ms;
	white-space: nowrap;
	color: var(--tg-theme-text-color);
}
.sliderCategory a:first-child{
	margin-right: 3px;
}
.sliderCategory a:last-child{
	margin-left: 3px;
}
.sliderCategory a.is-selected{
	color: var(--tg-theme-button-text-color);
	background-color: var(--tg-theme-button-color);
}