section.errorPage{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    img {
        width: 150px;
        height: 150px;
    }
    h1 {
        font-size: 35px;
    }
    p.first {
        font-size: 25px;
    }
    p.last {
        color: var(--tg-theme-hint-color);
    }
}
