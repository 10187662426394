.productListBusket .capsule {
    position: relative;
    width: 100%;
    height: 160px;
    margin-bottom: 15px;
    transition: height 120ms linear;
    .cardProduct.Busket {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: height 120ms linear, box-shadow 200ms linear, opacity 200ms linear;
        img {
            height: 100px;
            filter: brightness(60%) contrast(80%);
            transition: height 120ms linear;
        }
        h3 {
            position: absolute;
            top: 10px;
            left: 20px;
            max-width: 65%;
            height: 30px;
            margin: 0;
            color: white;
            font-size: 20px;
            -webkit-line-clamp: 1;
            transition: height 120ms linear;
        }
        .finalCost {
            position: absolute;
            top: 10px;
            right: 15px;
            width: 90px;
            border-radius: 5px;
            color: white;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            background-color: #15BB39;
        }
        p {
            position: absolute;
            top: 45px;
            left: 15px;
            height: 42px;
            width: 90%;
            margin: 0;
            color: white;
            -webkit-line-clamp: 2;
            font-size: 16px;
            transition: height 120ms linear, top 120ms linear;
        }
        .totalPriceItem {
            display: inline-block;
            position: absolute;
            left: 15px;
            bottom: 15px;
            padding: 3px 5px 3px 5px;
            font-size: 18px;
            font-weight: bold;
            background: var(--tg-theme-secondary-bg-color);
        }
        .buttonSpaceBusket {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }
    }
}
