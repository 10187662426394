.ol1 {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    margin: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 35px;
    font-weight: 700;
    border-bottom: 1px solid var(--tg-theme-hint-color);
    z-index: 999;
}
.orderList {
    margin: 60px 10px 0 10px;
    section {
        margin: 15px 0;
        .title {
            margin: 0 0 10px 25px;
            font-size: 12px;
            color: var(--tg-theme-hint-color);
        }
    }
    section:nth-child(1){
        padding-top: 5px;
        .paymentMethod {
            border-radius: 10px;
            box-shadow: 0px 0px 70px 2px var(--tg-theme-secondary-bg-color);
            overflow: hidden;
            .option {
                font-size: 16px;
                padding: 15px 0 20px 15px;                
            }
            .option.active {
                background-color: var(--tg-theme-button-color);
            }
        }
    }
    section:nth-child(2) {
        overflow: visible;
        box-shadow: none;
        .address {
            border-radius: 10px;
            background-color: var(--tg-theme-secondary-bg-color);
            padding: 15px 10px 20px 15px;
        }
    }
    section:nth-child(3) {
        padding: 15px;
        border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
        .totalPrice {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            span:last-child {
                font-weight: 700;
            }
        }
        .warning {
            margin-top: 5px;
        }
    }
    .police {
        font-size: 14px;
        color: var(--tg-theme-hint-color);
    }
}