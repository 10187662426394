body{
	font-size: 1rem;
	margin: 0px;
	padding-bottom: 10px;
	color: var(--tg-theme-text-color);
	background-color: var(--tg-theme-bg-color);
	font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",
	Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji",
	"Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
body::-webkit-scrollbar{
	width: 0;
}