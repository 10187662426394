.cardProduct {
	border-radius: 10px;
	overflow: hidden;
	background-color: var(--tg-theme-bg-color);
	box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
	img {
		width: 100%;
		object-fit: cover;
	}
	h3 {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	p {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		color: var(--tg-theme-hint-color);
	}
}
.entering {
	animation: zoom-out 400ms linear forwards;
}
@keyframes zoom-out {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0.95);
	}
}
.entered {
	animation: zoom-in 120ms linear forwards;
}
@keyframes zoom-in {
	from {
		transform: scale(0.95);
	}
	to {
		transform: scale(1);
	}
}
.cardProduct.Menu {
	position: relative;
	display: flex;    
	flex-direction: column;
	justify-content: space-between;
	height: 280px;
	transition: opacity 150ms ease-out;
	img {
		height: 120px;
	}
	.surface-stop {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 120px;
		object-fit: contain;
		letter-spacing: .5rem;
		font-size: 4rem;
		font-weight: 500;
		color: rgb(220, 220, 220);
		background-color: rgba(240, 248, 255, 0.5);
	}
	h3 {
		max-height: 50px;
		margin: 0px 5px 0px 5px;
		font-size: 18px;
		-webkit-line-clamp: 2;
	}
	p {
		height: 60px;
		margin: 0px 5px 0px 5px;
		font-size: 14px;
		-webkit-line-clamp: 3;
	}
}
.cardProduct.hide {
	opacity: 0;
}