.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--tg-theme-bg-color);
}
.sk-chase {
    width: 128px;
    height: 128px;
    position: relative;
    -webkit-animation: sk-chase 2.5s infinite linear both;
    animation: sk-chase 2.5s infinite linear both;
}
.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-animation: sk-chase-dot 2s infinite ease-in-out both;
    animation: sk-chase-dot 2s infinite ease-in-out both;
}
.sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #3626a7;
    border-radius: 100%;
    -webkit-animation: sk-chase-dot-before 2s infinite ease-in-out both;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1) {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
@-webkit-keyframes sk-chase {
    100% {
      transform: rotate(360deg);
    }
}
@keyframes sk-chase {
    100% {
      transform: rotate(360deg);
    }
}
@-webkit-keyframes sk-chase-dot {
    80%, 100% {
      transform: rotate(360deg);
    }
}
@keyframes sk-chase-dot {
    80%, 100% {
      transform: rotate(360deg);
    }
}
@-webkit-keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%, 0% {
      transform: scale(1);
    }
}
@keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%, 0% {
      transform: scale(1);
    }
}
.sk-chase {
    width: 40px;
    height: 40px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
}
.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2.0s infinite ease-in-out both;
}
.sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: /*var(--tg-theme-button-color)*/ #3626a7;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
@keyframes sk-chase {
    100% { transform: rotate(360deg); }
}
@keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); }
}
@keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4);
    } 100%, 0% {
      transform: scale(1.0);
    }
}