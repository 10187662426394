.cardProduct .buttonSpace{
	width: 100%;
	height: 40px;
	text-align: center;
}
.overlay .buttonSpace{
	height: 50px;
	margin-top: 15px;
	text-align: center;
}
.buttonAddToBusket{
	width: 100%;
	height: 100%;
	border: none;
	border-radius: 10px;
	font-size: 14px;
	color: var(--tg-theme-button-text-color);
	background:  var(--tg-theme-button-color);
}
.overlay .buttonAddToBusket{
	font-size: 20px;
}
.buttonReduce {
    width: calc(35% - 10px);
    height: 100%;
	border-radius: 10px;
	font-size: 14px;
	color: var(--tg-theme-button-text-color);
	background: var(--tg-theme-button-color);
	border: none;
}
.quantity{
    width: calc(30% - 10px);
    height: 100%;
    padding: 0;
	margin: 0 10px 0 10px;
    text-align: center;
	border: none;
	border-radius: 5px;
	font-size: 14px;
	color: var(--tg-theme-text-color);
	background: var(--tg-theme-secondary-bg-color);
}
.quantity:focus{
    outline: none;
}
.buttonIncrease{
    width: calc(35% - 10px);
    height: 100%;
	border: none;
	border-radius: 10px;
	font-size: 14px;
	color: var(--tg-theme-button-text-color);
	background: var(--tg-theme-button-color);
}
.overlay .buttonReduce, 
.overlay .quantity, 
.overlay .buttonIncrease{
    font-size: 22px;
}